<template>
  <ul
    id="member-search-list"
    class="member-search-list list-reset"
    role="listbox"
    aria-labelledby="member-search-list"
  >
    <member-search-result
      v-for="member in members"
      :key="`member-search${member.id}`"
      :user="member"
      :selected="isSelectedMember(member)"
      @click="handleSubmit"
      @hover="selectMember"
    />
  </ul>
</template>

<script>
import MemberSearchResult from '@/components/Search/MemberSearchResult.vue';

export default {
  name: 'MemberSearchList',
  components: {
    MemberSearchResult,
  },
  props: {
    members: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedMember: {},
    };
  },
  computed: {
    selectedMemberIndex() {
      return this.members.findIndex(this.isSelectedMember);
    },
    firstMemberIsSelected() {
      return this.selectedMemberIndex === 0;
    },
    lastMemberIsSelected() {
      return this.selectedMemberIndex === this.members.length - 1;
    },
    noSelectedMember() {
      return Object.keys(this.selectedMember).length === 0;
    },
  },
  watch: {
    members(to) {
      if (!to) { return; }
      // The first member is selected by default
      [this.selectedMember] = to;
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleArrowKeys);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleArrowKeys);
  },
  methods: {
    handleArrowKeys(event) {
      if (this.members.length <= 0) { return; }
      const ARROW_DOWN = 40;
      const ARROW_UP = 38;
      const ENTER = 13;

      if (event.keyCode === ARROW_DOWN) {
        this.selectNext();
        event.preventDefault();
      }
      if (event.keyCode === ARROW_UP) {
        this.selectPrevious();
        event.preventDefault();
      }
      if (event.keyCode === ENTER) {
        this.handleSubmit();
        event.preventDefault();
      }
    },
    selectNext() {
      if (this.lastMemberIsSelected) {
        [this.selectedMember] = this.members;
        return;
      }
      this.selectedMember = this.members[this.selectedMemberIndex + 1];
    },
    selectPrevious() {
      if (this.firstMemberIsSelected) {
        this.selectedMember = this.members[this.members.length - 1];
        return;
      }
      this.selectedMember = this.members[this.selectedMemberIndex - 1];
    },
    selectMember(member) {
      if (this.isSelectedMember(member)) { return; }
      const indexOfMember = this.members.findIndex((m) => m.id === member.id);
      this.selectedMember = this.members[indexOfMember];
    },
    isSelectedMember(member) {
      return member.id === this.selectedMember.id;
    },
    handleSubmit() {
      if (this.noSelectedMember) { return; }
      this.$emit('memberSelected', this.selectedMember);
    },
  },
};
</script>

<style lang="scss"> // no scoped
  // to ensure that styles can be overridden
  // by .mention-box__result-list when needed.
  @import '@/stylesheets/components/_member-search-list';
</style>

<docs>
This component provides a list of users which can be navigated using keyboard input
as well as mouse input.

When a member is selected, emit the memberSelected event with the user that has been selected.

</docs>
