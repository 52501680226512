<template>
  <li
    class="member-search-result"
    :class="{ 'member-search-result--selected': selected }"
    role="option"
    :aria-selected="selected ? 'true' : 'false'"
    @click="$emit('click', user)"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <img
      class="member-search-result__image"
      :src="userAvatarSrc"
      :alt="imageAlt"
    >
    <span class="member-search-result__name">{{ user.username }}</span>
  </li>
</template>

<script>

import { avatarSrc } from '@/utils/image-helpers';

export default {
  name: 'MemberSearchResult',
  props: {
    user: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    userAvatarSrc() {
      return avatarSrc(this.user.avatar);
    },
    imageAlt() {
      return this.user.username || '';
    },
  },
  watch: {
    hover(to) {
      if (to) {
        this.$emit('hover', this.user);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_member-search-result';
</style>

<docs>
</docs>
