/**
 * Mentions are stored as <inline-mention username="Tyler Cain" user-id="555" />
 * This mixin takes inline components and converts them to a template, so they can render.
 *
 * The mixin requires a `body` data attribute or param on the parent component,
 * this will be converted into bodyWithMentions which should be rendered in the
 * template in place of the body.
 */
export default {
  data() {
    return {
      bodyWithMentions: '',
    };
  },
  mounted() {
    this.bodyWithMentions = this.mentionShortcodeToInlineMention();
  },
  methods: {
    mentionShortcodeToInlineMention() {
      // Template compiler is not available in test, return the HTML in test instead
      if (process.env.NODE_ENV === 'test') { return this.body; }
      return { template: `<div>${this.body}</div>` };
    },
  },
};
