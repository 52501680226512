<template>
  <div
    ref="contentEditable"
    role="textbox"
    contenteditable="true"
    aria-multiline="true"
    @input="contentEditableEventHandler"
    @blur="onBlur"
    @focus="handleFocus"
  />
</template>

<script>
import isInternetExplorer from '@/utils/msie';

export default {
  name: 'ContentEditableInput',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectionRange: undefined,
    };
  },
  computed: {
    content: {
      get() {
        return this.$refs.contentEditable.innerHTML;
      },
      set(value) {
        this.$refs.contentEditable.innerHTML = value;
      },
    },
    hasSelectionRange() {
      return typeof this.selectionRange === 'object';
    },
  },
  watch: {
    value(to) {
      if (to === this.$refs.contentEditable.innerHTML) { return; }
      // Updates content if the parent updated the prop, this occurs when a mention is added
      this.$refs.contentEditable.innerHTML = to;
      // This is necessary otherwise cursor placement returns to the start
      this.placeCursorAtEnd();
    },
  },
  mounted() {
    this.addContentEditableEventListeners();
  },
  beforeDestroy() {
    this.removeContentEditableEventListeners();
  },
  methods: {
    handleFocus() {
      this.$emit('focus');
    },
    focus() {
      this.$el.focus();
      this.restoreSelection();
    },
    onBlur() {
      this.saveSelection();
    },
    removeLastCharacter() {
      this.content = this.content.substring(0, this.content.length - 1);
      this.placeCursorAtEnd();
    },
    clear() {
      this.content = '';
    },
    contentEditableEventHandler() {
      this.$emit('input', this.$refs.contentEditable.innerHTML);
    },
    forceUpdate() {
      this.contentEditableEventHandler();
    },
    addContentEditableEventListeners() {
      this.$refs.contentEditable.addEventListener('paste', this.handlePasting);

      if (isInternetExplorer()) {
        this.$refs.contentEditable.addEventListener('keydown', this.contentEditableEventHandler);
        this.$refs.contentEditable.addEventListener('blur', this.contentEditableEventHandler);
      }
    },
    removeContentEditableEventListeners() {
      if (!this.$refs.contentEditable) { return; }
      this.$refs.contentEditable.removeEventListener('paste', this.handlePasting);

      if (isInternetExplorer()) {
        // keydown requires a timeout to ensure the last character is captured
        this.$refs.contentEditable.removeEventListener('keydown', this.contentEditableEventHandler);
        this.$refs.contentEditable.removeEventListener('blur', this.contentEditableEventHandler);
      }
    },
    handlePasting(event) {
      event.preventDefault();
      // Gets plain text clipboard content without any styles
      const paste = (event.clipboardData || window.clipboardData).getData('text');
      // this fixes an issue with safari pasting, where the cursor gets put before the
      // pasted content. Note that this document.execCommand is deprecated so it should not be used
      // alone.  Currently safari still supports it.
      // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand
      if (window.safari !== undefined && document.execCommand) {
        document.execCommand('insertText', false, paste);
        this.contentEditableEventHandler();
        return;
      }
      // Get the current selection
      const selection = window.getSelection();
      if (!selection.rangeCount) { return; }
      selection.deleteFromDocument();
      // paste the clipboard data
      selection.getRangeAt(0).insertNode(document.createTextNode(paste));
      // emit input event so the contenteditable's parent state is updated
      this.contentEditableEventHandler();
      this.placeCursorAtEndOfPastedContent();
    },
    // Places caret at the end of the input, this is necessary when manipulating
    // the content of the contenteditable. Taken from here, no need for the x-browser
    // stuff as support is good
    // https://stackoverflow.com/questions/4233265/contenteditable-set-caret-at-the-end-of-the-text-cross-browser
    placeCursorAtEnd() {
      // Does not use this.focus() to prevent restoring selection.
      this.$el.focus();
      const range = document.createRange();
      const sel = window.getSelection();

      range.selectNodeContents(this.$refs.contentEditable);
      range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(range);
    },
    placeCursorAtEndOfPastedContent() {
      this.$refs.contentEditable.focus();
      const sel = window.getSelection();
      let range;

      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
      }
    },

    // Saving the selection as a data attribute is necessary in order to preserve the cursor placement
    // when interacting with adjacent components like image upload and deleting uploaded images
    saveSelection() {
      if (window.getSelection) {
        const sel = window.getSelection();
        let range;

        if (sel.getRangeAt && sel.rangeCount) {
          range = sel.getRangeAt(0);
          this.selectionRange = range;
        }
      }
    },

    // restore the cursor placement, this happens on focus and can be triggered programatically
    // does nothing if there is no saved selectionRange
    restoreSelection() {
      if (!this.hasSelectionRange) { return; }

      if (window.getSelection) {
        const sel = window.getSelection();

        sel.removeAllRanges();
        sel.addRange(this.selectionRange);
      } else if (document.selection && this.selectionRange.select) {
        this.selectionRange.select();
      }
      this.selectionRange = undefined;
    },

    // Content cleaning methods
    removeSpans(content) {
      // This is cleaning up some of the extra <br> tags and spans with
      // inline styles that get added when someone copies and pastes text into the input.
      return content
        .replace(/<span[^>]*><br>/g, '')
        .replace(/<span[^>]*>/g, '')
        .replace(/<\/span><br>/g, '')
        .replace(/<\/span>/g, '');
    },
    removeExtraDivs(content) {
      const removeExtraTags = content.replace(/<div><br><\/div>/g, '<br>');
      return removeExtraTags.replace(/<div>/g, '<br>').replace(/<\/div>/g, '');
    },
  },
};
</script>

<docs>
This component provides a div with contenteditable enabled along with methods
to handle common and useful functionality needed to simulate a native input like
handling pasting of content, setting focus, and cursor placement. As well as some utility methods
to handle cleaning stray or redundant HTML tags from content.

The component is designed to work with v-model to make it easily usable throughout the application.
</docs>
