// Wraps a promise, adds a cancel method to reject the promise before it is resolved
function makeCancellable(promise) {
  let rejectFn;

  const wrappedPromise = new Promise((resolve, reject) => {
    rejectFn = reject;

    Promise.resolve(promise)
      .then(resolve)
      .catch(reject);
  });

  wrappedPromise.cancel = () => {
    rejectFn({ canceled: true });
  };

  return wrappedPromise;
}

export {
  makeCancellable,
};
